<template>
  <div class="newSeeds">
    <div class="title">
      <div class="title-bottom">
        <div class="title-bottom-left">
          <!-- <div class="search" style="margin-right: 2vw;">
                <span style="margin-right:.5vw;">在线状态</span>
                <el-radio-group v-model="online" >
                    <el-radio :label="online1">全部</el-radio>
                    <el-radio :label="online2">在线</el-radio>
                    <el-radio :label="online3">离线</el-radio>
                </el-radio-group>
            </div> -->
          <!-- <div class="search">
            <span style="margin-right:.5vw;">开关状态</span>
            <el-radio-group v-model="switchStatus">
              <el-radio :label="switchStatus1">全部</el-radio>
              <el-radio :label="switchStatus2">在线</el-radio>
              <el-radio :label="switchStatus3">离线</el-radio>
            </el-radio-group>
          </div> -->
        </div>

        <div class="title-bottom-right">
          <el-select
            style="margin-right: 0.5vw; width: 10vw"
            v-model="value1"
            placeholder="节点名称"
            @change="changeList"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="(item, i) in selList"
              :key="i"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <!-- <el-select
            style="width: 25%;margin-right:.5vw;"
            v-model="value2"
            placeholder="轮灌组"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in newSelectList2"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select> -->
          <!-- <el-input
            v-model="pestName"
            class="enquiryInp"
            style="width:30%;"
            placeholder="请输入设备名称"
          ></el-input> -->
          <!-- <el-button
            @click="searchBtn"
            type="primary"
            style="margin-left: 1vw;"
            size="small"
            >查询</el-button
          > -->
        </div>
      </div>
      <!-- <div class="title-bottom-left">
        <el-button type="primary" size="small" @click="addBtn">添加</el-button>
      </div> -->
    </div>
    <el-table
      v-loading="loading"
      ref="multipleTable"
      class="table-update"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%; height: 78vh; overflow-y: auto"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        label="序号"
        type="index"
        show-overflow-tooltip
        align="center"
      />
      <el-table-column
        label="设备名称"
        prop="name"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="节点名称"
        prop="typeName"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <!-- <el-table-column
        label="信号"
        prop="data4"
        show-overflow-tooltip
        align="center"
      ></el-table-column> -->
      <!-- 开关 -->
      <el-table-column
        prop="state"
        label="开关"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <!-- <el-switch :value="scope.row.data5 == 'true'" disabled
              @change=switchChange($event,status)>
          </el-switch> -->
          <el-switch @change="changeSwitch(scope.row)" :value="scope.row.state">
          </el-switch>
        </template>
      </el-table-column>

      <el-table-column
        label="节点"
        prop="index"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <!-- <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <span
            @click="updateClick(scope.$index, scope.row)"
            style="color: #3A9BFF;margin-right:.5vw"
            >编辑</span
          >
          <span
            @click="delList(scope.row)"
            style="color: #3A9BFF;margin-left:.5vw"
            >删除</span
          >
        </template>
      </el-table-column> -->
    </el-table>
    <!-- <div v-if="tableData.length >= 10" class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="400"
      >
      </el-pagination>
    </div> -->

    <!-- 新增 -->
    <el-dialog
      title=""
      :visible.sync="addForm"
      width="31vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">新增信息</span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          "
        >
        </span>
      </div>
      <div class="addAlaForm">
        <el-form
          ref="addTableData"
          :rules="rules"
          :model="addTableData"
          label-width="10vw"
        >
          <el-form-item label="设备名称">
            <el-input v-model="addTableData.equipName"></el-input>
          </el-form-item>
          <el-form-item label="设备类型">
            <el-input v-model="addTableData.equipType"></el-input>
          </el-form-item>
          <el-form-item label="信号">
            <el-input v-model="addTableData.sign"></el-input>
          </el-form-item>
          <el-form-item label="开关">
            <el-switch v-model="addTableData.upDown"></el-switch>
          </el-form-item>
          <el-form-item label="轮灌组">
            <el-input v-model="addTableData.group"></el-input>
          </el-form-item>
          <el-form-item size="large">
            <el-button
              class="saveBtn"
              type="primary"
              @click="saveForm('addTableData')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 编辑 -->
    <el-dialog
      title=""
      :visible.sync="updateForm"
      width="31vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">编辑详情</span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          "
        >
        </span>
      </div>
      <div class="addAlaForm">
        <el-form
          ref="upTableData"
          :rules="rules"
          :model="upTableData"
          label-width="10vw"
        >
          <el-form-item label="设备名称" prop="name">
            <el-input v-model="upTableData.data1"></el-input>
          </el-form-item>
          <el-form-item label="设备类型" prop="content">
            <el-input v-model="upTableData.data2"></el-input>
          </el-form-item>
          <el-form-item label="信号" prop="content">
            <el-input v-model="upTableData.data4"></el-input>
          </el-form-item>
          <el-form-item label="开关">
            <el-switch v-model="upTableData.data5"></el-switch>
          </el-form-item>
          <el-form-item label="轮灌组" prop="link">
            <el-input v-model="upTableData.data3"></el-input>
          </el-form-item>
          <el-form-item size="large">
            <el-button
              class="saveBtn"
              type="primary"
              @click="updateSubmit('upTableData')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { noShake } from "../../../components/noShakes";
export default {
  data() {
    return {
      selList: [],
      stateList: {
        1: true,
        0: false,
      },
      upTableData: {},
      activities: [],
      tableData: [],
      tableData2: [],
      newSelectList: [],
      newSelectList2: [],
      loading: false,
      nowIndex: 0,
      currentPage4: 4,
      value1: "",
      value2: "",
      online: 1,
      online1: 1,
      online2: 2,
      online3: 3,

      switchStatus: 1,
      switchStatus1: 1,
      switchStatus2: 2,
      switchStatus3: 3,
      status: 1,
      page: 1,
      size: 8,
      listTotal: 0,
      addForm: false,
      updateForm: false,
      pestName: "",
      // 新增
      addTableData: {
        equipName: "",
        equipType: "",
        sign: "",
        upDown: false,
        group: "",
      },
      rules: {
        equipName: [
          {
            required: true,
            message: "图片不能为空",
            trigger: ["blur", "change"],
          },
        ],
        equipType: {
          required: true,
          message: "名称不可为空",
          trigger: "change",
        },
        group: { required: true, message: "作物不可为空", trigger: "change" },
        sign: [
          {
            required: true,
            message: "分类不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      logisticsForm: {
        name: "",
        region: "",
      },
      page: 1,
      size: 10,
      listTotal: 0,
      // 编辑
      upTableData: {
        equipName: "",
        equipType: "",
        sign: "",
        upDown: "",
        group: "",
      },
    };
  },
  mounted() {
    this.getListData();
  },
  methods: {
    changeList() {
      this.getListData();
    },
    getListData() {
      let _this = this;
      this.$get("/deviceManageTwo/getDeviceLists", {
        page: 1,
        limit: 99999,
        type: 10,
        name: this.value1,
        deviceCode: "",
        groupId: "2",
      }).then(
        (res) => {
          if (res.data.state == "success") {
            let m = res.data.datas;
            let arr = [];
            let index = 0;
            for (let i = 0; i < m.length; i++) {
              if (
                m[i].data &&
                m[i].data.indexOf("|") > -1 &&
                m[i].type.indexOf("/") > -1
              ) {
                let ty = m[i].type.split("/");
                let da = m[i].data.split("|");
                for (let j = 0; j < ty.length; j++) {
                  if (da[j].trim() == "关") {
                    da[j] = false;
                  } else {
                    da[j] = true;
                  }
                  index++;
                  arr.push({
                    name: m[i].name,
                    state: da[j],
                    typeName: ty[j],
                    stateNum: m[i].onLineState,
                    deviceNumber: m[i].deviceNumber,
                    index: index,
                  });
                }
              }
            }
            this.tableData = arr;
            // _this.tableData = res.data.datas;
            // _this.tableData2 = res.data.datas;
            // //console.log(_this.tableData2);
            // var list1 = [];
            // var list2 = [];
            // this.tableData = [];
            // res.data.datas.forEach((val, i) => {
            //   if (
            //     val.data &&
            //     val.data.indexOf("|") > -1 &&
            //     val.type.indexOf("/") > -1
            //   ) {
            //     list1 = val.data.split("|");
            //     if(list1[list1.length-1].trim()==''){
            //     list1.pop();
            //     }
            //     list2 = val.type.split("/");
            //     list1.forEach((item, j) => {
            //       debugger;
            //       if(typeof item=='string'){
            //       if(item.toString().indexOf("开")>-1) item=1;
            //       if(item.toString().indexOf("关")>-1) item=0;
            //       }
            //       // debugger;
            //       if (item != "" && list2[j] != "") {
            //         this.tableData.push({
            //           name: val.name,
            //           state: item==0?false:true,
            //           typeName: list2[j],
            //           stateNum: val.onLineState,
            //           deviceNumber: val.deviceNumber,
            //           index: j + 1,
            //         });
            //       }
            //       this.selList.push(val.name);

            //     });
            //   }
            // });
            // this.selList = [...new Set(this.selList)];
            // this.loading = false;
            // _this.tableData2.unshift({
            //   data2: '全部',
            //   data3: '全部'
            // });
            // _this.tableData.forEach((ele, index) => {
            //   ele.data5 = eval(ele.data5);
            // });

            // let pullDown = [];
            // let pullDown2 = [];
            // this.tableData2.forEach((ele, index) => {
            //   pullDown.push(ele.data2);
            //   pullDown2.push(ele.data3);
            // });
            // _this.quchong(pullDown);
            // _this.quchong2(pullDown2);
          } else {
            this.loading = false;
            throw Error("aa");
          }
        },
        (err) => {
          this.loading = false;
          throw err;
        }
      );
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    updateClick(param1, param2) {
      //  //console.log(param2)
      // this.boxPrompt = '编辑消息';
      // this.updateForm = true;

      // this.upTableData = param2;
      // //console.log(this.upTableData)

      this.index = param1;
      let that = this;
      this.cid = param2.id;
      this.fileName = param2.data6;
      this.boxPrompt = "编辑消息";
      this.updateForm = true;
      this.$nextTick(() => {
        that.upTableData = JSON.parse(JSON.stringify(param2));
      });
    },
    quchong(arr) {
      var newStr = [];
      for (var i = 0; i < arr.length; i++) {
        if (newStr.indexOf(arr[i]) === -1) {
          newStr.push(arr[i]);
        }
      }
      this.newSelectList = newStr;
    },
    quchong2(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            arr.splice(j, 1);
            j--;
          }
        }
      }
      //console.log(arr);
      this.newSelectList2 = arr;
    },
    switchChange($e, ele) {
      //console.log($e, ele);
    },

    // 临时弹窗按钮
    refresh() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    addBtn() {
      this.addForm = true;
      this.boxPrompt = "新增消息";
      //console.log(this.addTableData);
    },
    //保存
    saveForm() {
      if (!this.addTableData.equipName) {
        this.$message.info("请输入设备名称");
        return false;
      }

      if (!this.addTableData.equipType) {
        this.$message.info("请输入设备类型");
        return false;
      }

      if (!this.addTableData.sign) {
        this.$message.info("请输入信号信息");
        return false;
      }

      if (!this.addTableData.group) {
        this.$message.info("请输入轮罐组信息");
        return false;
      }
      //console.log(this.addTableData);
      let _this = this;
      this.$refs["addTableData"].validate((valid) => {
        if (valid) {
          let data1 = this.addTableData.equipName;
          let data2 = this.addTableData.equipType;
          let data3 = this.addTableData.group;
          let data4 = this.addTableData.sign;
          // let data5 = this.addTableData.upDown === true ? 1 : this.addTableData.upDown === false ? 0 : 0;
          let data5 = this.addTableData.upDown;
          //console.log(typeof data5);
          this.$get("/monitor/insert", {
            id: 0,
            type: 10,
            data1,
            data2,
            data3,
            data4,
            data5,
          }).then(
            (res) => {
              _this.addForm = false;
              if (res.data.state == "success") {
                //console.log(res);
                this.getListData();
                this.$message.success("操作成功");
              } else {
              }
            },
            (err) => {
              throw err;
            }
          );
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },

    //编辑提交
    updateSubmit() {
      if (!this.upTableData.data1) {
        this.$message.info("请输入设备名称");
        return false;
      }

      if (!this.upTableData.data2) {
        this.$message.info("请输入设备类型");
        return false;
      }

      if (!this.upTableData.data4) {
        this.$message.info("请输入信号信息");
        return false;
      }

      if (!this.upTableData.data3) {
        this.$message.info("请输入轮罐组信息");
        return false;
      }

      //console.log(this.upTableData);
      let _this = this;
      if (true) {
        let uid = this.upTableData.id;
        let data1 = this.upTableData.data1;
        let data2 = this.upTableData.data2;
        let data3 = this.upTableData.data3;
        let data4 = this.upTableData.data4;
        // let data5 = this.upTableData.upDown === true ? 1 : this.addTableData.upDown === false ? 0 : 0;
        let data5 = this.upTableData.data5;
        //console.log(data5);
        this.$get("/monitor/update", {
          id: uid,
          type: 10,
          data1,
          data2,
          data3,
          data4,
          data5,
        }).then(
          (res) => {
            _this.updateForm = false;
            if (res.data.state == "success") {
              //console.log(res);
              this.getListData();
              this.$message.success("操作成功");
            } else {
            }
          },
          (err) => {
            throw err;
          }
        );
      }
    },
    searchBtn() {
      // 单选
      // let parameter1 = 'data4';
      let parameter2 = "data5";
      // let parameter1Value = this.online === 1 ? '' : this.online === 2 ? '在线' : this.online === 3 ? '离线' : '';
      let parameter2Value =
        this.switchStatus === 1
          ? ""
          : this.switchStatus === 2
          ? "true"
          : this.switchStatus === 3
          ? "false"
          : "";
      // 下拉
      let parameter3 = "data2";
      let parameter1 = "data3";
      let parameter3Value = this.value1;
      let parameter1Value = this.value2;
      //console.log(parameter3Value);
      // parameter1Value = parameter1Value === '全部' ? '' : parameter1Value;
      // parameter3Value = parameter3Value === '全部' ? '' : parameter3Value;
      // 输入框
      let data1 = this.pestName; // 设备名称
      // //console.log(parameter1Value,parameter2Value,parameter3Value,parameter4Value,data1)
      let _this = this;
      this.$get("/monitor/select", {
        type: 10,
        page: this.page,
        size: this.size,
        data1,
        parameter2,
        parameter2Value,
        parameter3,
        parameter3Value,
        parameter1,
        parameter1Value,
      }).then(
        (res) => {
          if (res.data.state == "success") {
            //console.log(res, 11);
            _this.tableData = res.data.datas;
            _this.tableData.forEach((ele, index) => {
              ele.data5 = eval(ele.data5);
            });
            //console.log(res.data.datas);
          } else {
            //console.log(2222);
          }
        },
        (err) => {
          throw err;
        }
      );
    },
    lookBtn() {
      this.$message({ message: "查看", type: "success" });
    },
    // noShaked:noShake('changeSwitch(row)'),

    changeSwitch(row) {
      this.loading = true;
      //console.log(row.state);
      //console.log(row);
      //console.log(row.index);
      let value;
      if (row.index < 10) {
        value = "0" + row.index;
      } else {
        value = row.index;
      }
      value += row.state == 0 ? "01" : "00";
      // this.$confirm('是否进行此操作, 是否继续?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      this.$post("/device/sendCommand", {
        type: 1,
        deviceType: 2,
        deviceNumber: row.deviceNumber,
        command: 201,
        value: value,
        devicePassword: "",
      }).then((res) => {
        //console.log(res);
        if (res.data.state === "success") {
          this.getListData();
          this.$message({
            message: "操作成功",
            type: "success",
          });
        } else {
          setTimeout(() => {
            this.$message({
              message: "操作失败",
            });
            this.loading = false;
          }, 1000);
        }
      });
      //  }).catch(() => {
      //     this.$message({
      //       type: 'info',
      //       message: '已取消删除'
      //     });
      //   });
    },

    //删除
    delList(item) {
      //console.log(item);
      let uid = item.id;
      var _this = this;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let m = this.tableData;
          for (let i = 0; i < m.length; i++) {
            if (item.id == m[i].id) {
              m.splice(i, 1);
            }
          }
          this.$message.success("删除成功");
          this.$get("/monitor/delete", { id: uid }).then((res) => {
            //console.log(res);
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              _this.getListData();
            } else {
              this.$message.info("删除失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange() {},
  },
};
</script>
<style scoped lang="less">
@import "../../../style/backTable.less";
// .addAlameBox /deep/ .el-dialog__header {
//   background: #3464e0;
//   padding: 13px 20px 10px;
// }
// .addAlameBox /deep/ .title-age {
//   font-size: 1.67vh;
//   font-family: Source Han Sans CN;
//   font-weight: 400;
//   color: #ffffff;
// }
// .addAlameBox /deep/ .el-dialog__close {
//   color: #fff;
//   font-size: 2.22vh;
// }
// .addAlameBox /deep/ .el-dialog__headerbtn {
//   position: absolute;
//   top: 15px;
//   right: 12px;
// }
// .addAlameBox /deep/ .el-button {
//   padding: 1.2vh 3vw !important;
// }
// .addAlameBox /deep/ .el-form-item__label {
//   width: 6vw !important;
//   font-size: 1.48vh;
//   font-family: Source Han Sans CN;
//   font-weight: 400;
//   color: #181818;
// }
// .el-timeline /deep/ .el-timeline-item {
//   margin-left: 15.5vh !important;
// }
// .el-timeline /deep/ .el-timeline-item__timestamp {
//   position: absolute;
//   left: -7vw;
//   top: -0.6vh;
// }
// .addAlameBox /deep/ .saveBtn2 {
//   padding: 1vh 6vw !important;
//   transform: translateX(64%);
// }
// .addAlaForm {
//   .info-one {
//     margin-bottom: 2vh;
//     span:nth-child(1) {
//       display: inline-block;
//       width: 7.5vw;
//       text-align: right;
//     }
//     span:nth-child(2) {
//       margin-left: 3vw;
//       white-space: nowrap;
//       color: #000;
//     }
//   }
// }

// // .addAlameBox /deep/ .saveBtn{
// //   padding: 2vh 4.5vw !important;
// // }

.addAlameBox /deep/ .el-form-item__content {
  width: 21vw;
}

// .addAlameBox /deep/ .el-textarea__inner {
//   resize: none;
//   min-height: 15vh !important;
// }

.el-radio {
  color: #606266;
  cursor: pointer;
  margin-right: 10px !important;
}
.el-radio {
  /deep/ .el-radio__label {
    padding-left: 5px;
  }
}
.newSeeds {
  // margin: 0 2vh;
  background: #fff;
  height: 90vh;
  padding: 2vh;
  box-sizing: border-box;
}
.pagingBox {
  margin-top: 2vh;
  text-align: center;
}
.operation {
  display: flex;
}
.operation div {
  margin-right: 1vh;
  cursor: pointer;
}
.title-top {
  display: flex;
  align-items: center;
}
.el-title-item {
  display: flex;
}
.el-title-item .el-form {
  display: flex;
}
.title-bottom-left /deep/ .el-form-item {
  margin-bottom: 0px !important;
  height: 100% !important;
}
.title-bottom-left /deep/ .el-form-item__label {
  color: #000 !important;
  font-weight: bold !important;
}
.el-title-item /deep/ .el-form-item__content {
  margin-left: 0 !important;
}
.el-title-item /deep/ .el-input__inner {
  height: 4vh;
  line-height: initial;
}
.table-update /deep/ .el-table__body-wrapper .el-tooltip {
  white-space: normal !important;
}
.table-update /deep/ .el-table__row td {
  padding: 20px 0 !important;
}
.blockBox .el-date-editor.el-input__inner {
  width: 26vw;
}
.title-bottom {
  //   margin: 2vh 0;
  margin-bottom: 2vh;
  //   height: 4vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /deep/ el-button {
    height: 2vh;
  }
  .title-bottom-left {
    display: flex;
    /deep/ button {
      font-size: 14px;
      color: #fff !important;
    }
  }
}
// .newSeeds /deep/ .el-button {
//   padding: 1vh 0.5vw;
// }
.title-bottom-right {
  display: flex;
  align-items: center;
  /deep/ button {
    font-size: 14px;
  }
  /deep/ button:nth-child(2) {
    color: #000;
  }
}
</style>
